<template>
  <el-button
      v-loading.fullscreen.lock="loading.state"
      element-loading-text="正在处理，莫慌……"
      @click="click">{{ text }}
  </el-button>
</template>

<script>
export default {
  name: "myButton",
  data() {
    return {
      text: this.$slots.default[0].text, //获取标签之间的值
      loading: {
        state: false
      },
    }
  },
  methods: {
    click() {
      //点击是默认开启mask
      this.loading.state = true;
      this.$emit('click', this.loading)
    }
  }
}
</script>

<style scoped>

</style>