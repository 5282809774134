import request from "@/js/request";
import {baseURL} from "@/js/getawayModule";

/**
 * 获取模板ID
 * @param params
 * @returns {AxiosPromise}
 */
export function getTemplateByKey(params) {
  return request({
    url: `${baseURL.resource}/templates/getTemplateByKey`,
    method: 'get',
    params: params
  })
}

/**
 * 注册供应商
 * @param params
 * @returns {AxiosPromise}
 */
export function registerSupplier(data) {
  return request({
    url: `${baseURL.resource}/suppliers/register`,
    method: 'post',
    data: data
  })
}

/**
 * 注册供应商账号
 * @param params
 * @param data
 * @returns {AxiosPromise}
 */
export function registerAccount(params, data) {
  return request({
    url: `${baseURL.resource}/suppliers/registerAccount`,
    method: 'post',
    params: params,
    data: data
  })
}

/**
 * 获取校验码
 * @param params
 * @returns {AxiosPromise}
 */
export function getVerifyCode(data) {
  return request({
    url: `${baseURL.resource}/getVerifyCode`,
    method: 'post',
    data: data
  })
}
